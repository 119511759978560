import { inject, Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, type StateContext } from '@ngxs/store';
import {
  catchError,
  filter,
  map,
  repeat,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { syncLoadProgress, syncOperationProgress } from '@cosmos/state';
import type {
  BloomProduct,
  Distributor,
  DistributorDesign,
  ModelWithLoadingStatus,
  OperationStatus,
} from '@cosmos/types-common';
import type { CollectionProductSearchResultItem } from '@esp/collections/types';

import {
  CustomerPortalCollectionActions,
  CustomerPortalProductActions,
} from '../actions';
import { CollectionsService, ProductService } from '../services';

export interface CustomerPortalProductStateModel
  extends ModelWithLoadingStatus {
  product: BloomProduct | null;
  collectionProducts: CollectionProductSearchResultItem[] | null;
  collectionOperation?: OperationStatus;
  productDistributor?: Distributor | null;
  distributorDesign?: DistributorDesign | null;
}

type ThisStateModel = CustomerPortalProductStateModel;
type ThisStateContext = StateContext<ThisStateModel>;

@State<CustomerPortalProductStateModel>({
  name: 'customerPortalProduct',
  defaults: {
    product: null,
    collectionProducts: null,
    productDistributor: null,
    distributorDesign: null,
  },
})
@Injectable()
export class CustomerPortalProductState {
  private readonly _collectionsService = inject(CollectionsService);
  private readonly _productsService = inject(ProductService);

  @Action(CustomerPortalCollectionActions.Get)
  private _getCollection(
    ctx: ThisStateContext,
    { collectionId, productId }: CustomerPortalCollectionActions.Get
  ) {
    ctx.patchState({ product: null });
    const REPEAT_COUNT = 5;

    const getProducts$ = this._collectionsService
      .searchProducts(collectionId, {
        from: 1,
        size: 250,
      })
      .pipe(
        repeat({ count: REPEAT_COUNT, delay: 500 }), //need to retry as data may not be available from Algolia instantly.
        skipWhile((response) => response.Results?.length === 0),
        take(1),
        filter((response) => Boolean(response.Results?.length)),
        map((response) =>
          response.Results!.filter((it) => Boolean(!it.IsDeleted))
        ),
        tap((results) => {
          if (results) {
            ctx.patchState({ collectionProducts: results });

            // If the productId is not provided (hence is missing in the URL), navigate to the first product in the collection
            if (!productId) {
              ctx.dispatch(
                new Navigate([
                  'collections',
                  collectionId,
                  'product',
                  results[0].Id,
                ])
              );
            }
          }
        }),
        catchError(() => {
          return ctx.dispatch(new Navigate(['/not-found']));
        })
      );

    const getCollection$ = this._collectionsService.get(collectionId);

    return getCollection$.pipe(
      switchMap((collection) => {
        // Calculate if the collection is expired
        const isExpired = collection.ExpirationDate
          ? new Date().getTime() > new Date(collection.ExpirationDate).getTime()
          : false;

        // If the collection is expired, navigate to the link-expired page
        if (isExpired) {
          return ctx.dispatch(
            new Navigate(['collections', collectionId, 'link-expired'])
          );
        }

        // if the collection is not expired, return the getProducts$ observable
        return getProducts$;
      }),
      syncOperationProgress(ctx, 'collectionOperation'),
      catchError(() => {
        return ctx.dispatch(new Navigate(['/not-found']));
      })
    );
  }

  @Action(CustomerPortalCollectionActions.GetProduct)
  private _getProduct(
    ctx: ThisStateContext,
    { collectionId, productId }: CustomerPortalCollectionActions.GetProduct
  ) {
    ctx.patchState({ product: null });

    return this._collectionsService.getProduct(collectionId, productId).pipe(
      tap((product) => ctx.patchState({ product })),
      syncLoadProgress(ctx)
    );
  }

  @Action(CustomerPortalCollectionActions.GetProductDistributor)
  private _getProductDistributor(
    ctx: ThisStateContext,
    { collectionId }: CustomerPortalCollectionActions.GetProductDistributor
  ) {
    return this._collectionsService.getProductDistributor(collectionId).pipe(
      tap((distributor) => {
        ctx.patchState({
          productDistributor: distributor,
        });
      })
    );
  }

  @Action(CustomerPortalCollectionActions.GetDistributorDesign)
  private _getProductPageDesign(
    ctx: ThisStateContext,
    { collectionId }: CustomerPortalCollectionActions.GetDistributorDesign
  ) {
    return this._collectionsService.getDistributorDesign(collectionId).pipe(
      tap((design) => {
        ctx.patchState({
          distributorDesign: design,
        });
      })
    );
  }

  @Action(CustomerPortalProductActions.GetProductDetail)
  private _getProductDetailProduct(
    ctx: ThisStateContext,
    { productId }: CustomerPortalProductActions.GetProductDetail
  ) {
    return this._productsService.getProduct(productId).pipe(
      tap((product) => {
        ctx.patchState({
          product: product,
        });
      }),
      syncLoadProgress(ctx),
      syncOperationProgress(ctx, 'collectionOperation')
    );
  }

  @Action(CustomerPortalProductActions.GetDisInfo)
  private _getPDPDistributor(
    ctx: ThisStateContext,
    { productId }: CustomerPortalProductActions.GetDisInfo
  ) {
    return this._productsService.getProductDistributor(productId).pipe(
      tap((distributor) => {
        ctx.patchState({
          productDistributor: distributor,
        });
      })
    );
  }

  @Action(CustomerPortalProductActions.GetDistributorDesign)
  private _getPDPDesign(
    ctx: ThisStateContext,
    { productId }: CustomerPortalProductActions.GetDistributorDesign
  ) {
    return this._productsService.getDistributorDesign(productId).pipe(
      tap((design) => {
        ctx.patchState({
          distributorDesign: design,
        });
      })
    );
  }
}
