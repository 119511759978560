const ACTION_SCOPE = '[CustomerPortal Collection]';

export namespace CustomerPortalCollectionActions {
  /**
   * If the productId is provided, it will be used to fetch the product details of that product
   * and not the first product of the collection.
   */
  export class Get {
    static readonly type = `${ACTION_SCOPE} Get`;

    constructor(
      readonly collectionId: number,
      readonly productId?: number
    ) {}
  }

  export class GetProduct {
    static readonly type = `${ACTION_SCOPE} Get Product`;

    constructor(
      readonly collectionId: number,
      readonly productId: number
    ) {}
  }

  export class GetProductDistributor {
    static readonly type = `${ACTION_SCOPE} Get Distributor`;

    constructor(public collectionId: number) {}
  }

  export class GetDistributorDesign {
    static readonly type = `${ACTION_SCOPE} Get Collection Design`;

    constructor(public collectionId: number) {}
  }
}
