import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type {
  BloomProduct,
  Distributor,
  DistributorDesign,
} from '@cosmos/types-common';
import { EspRestClient } from '@esp/common/data-access-rest-client';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends EspRestClient<BloomProduct> {
  override url = 'products';

  constructor(configService: ConfigService) {
    super(configService.get('sednaApiUrl'));
  }

  getProduct(id: number): Observable<BloomProduct> {
    return this.http.get<BloomProduct>(`${this.uri}/${id}`);
  }

  getProductDistributor(productId: number): Observable<Distributor> {
    return this.http.get<Distributor>(`${this.uri}/${productId}/distprofile`);
  }

  getDistributorDesign(productId: number): Observable<DistributorDesign> {
    return this.http.get<DistributorDesign>(`${this.uri}/${productId}/design`);
  }
}
