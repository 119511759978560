const ACTION_SCOPE = '[CustomerPortal ProductDetail]';

export namespace CustomerPortalProductActions {
  export class GetProductDetail {
    static readonly type = `${ACTION_SCOPE} Get ProductDetail Product`;

    constructor(public productId: number) {}
  }

  export class GetDistributorDesign {
    static readonly type = `${ACTION_SCOPE} Get ProductDetail Distributor Design`;

    constructor(public productId: number) {}
  }

  export class GetDisInfo {
    static readonly type = `${ACTION_SCOPE} Get ProductDetail Distributor Info`;

    constructor(public productId: number) {}
  }
}
