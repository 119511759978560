import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type {
  BloomProduct,
  Distributor,
  DistributorDesign,
} from '@cosmos/types-common';
import type {
  Collection,
  CollectionProductSearchResultItem,
} from '@esp/collections/types';
import { EspRestClient } from '@esp/common/data-access-rest-client';
import { SearchCriteria, type SearchResult } from '@esp/common/types';

@Injectable({
  providedIn: 'root',
})
export class CollectionsService extends EspRestClient<Collection> {
  override url = 'collections';
  override searchMethod = 'POST' as const;

  constructor(configService: ConfigService) {
    super(configService.get('sednaApiUrl'));
  }

  searchProducts(id: number, criteria: SearchCriteria = new SearchCriteria()) {
    return this.http.post<SearchResult<CollectionProductSearchResultItem>>(
      `${this.uri}/${id}/productsearch`,
      criteria
    );
  }

  getProduct(
    collectionId: number,
    productId: number
  ): Observable<BloomProduct> {
    return this.http.get<BloomProduct>(
      `${this.uri}/${collectionId}/products/${productId}`
    );
  }

  getProductDistributor(collectionId: number): Observable<Distributor> {
    return this.http.get<Distributor>(
      `${this.uri}/${collectionId}/distprofile`
    );
  }

  getDistributorDesign(collectionId: number): Observable<DistributorDesign> {
    return this.http.get<DistributorDesign>(
      `${this.uri}/${collectionId}/design`
    );
  }
}
